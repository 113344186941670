@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-primary;
    }
    .btn {
        @apply rounded-md font-medium grow-0 w-auto truncate focus:outline-none focus:ring;
    }
    .btn-sm {
        @apply btn px-6 py-1;
    }
    .btn-md {
        @apply btn px-10 py-2;
    }
    .btn-lg {
        @apply btn px-12 py-3;
    }
    .container-screen {
        @apply max-w-[90vw] xl:max-w-[1200px] mx-auto;
    }

    .tracker-item {
        @apply flex-1 relative pt-2 md:pt-0 md:pl-8 md:pb-32 border-t-3 md:border-t-0 md:border-l-3;
    }

    .tracker-item_last {
        @apply flex-1 relative pt-2 md:pt-0 md:pl-8 border-t-3 md:border-t-0 md:border-l-3;
    }

    .tracker-dot {
        @apply absolute left-[50%] md:left-0 top-[-55%] md:top-0 translate-y-[50%] translate-x-[-50%] block w-4 h-4 rounded-full;
    }

    .tracker-dot_first {
        @apply absolute left-0 top-[-55%] md:top-0 translate-y-[50%] md:translate-y-0 translate-x-[-50%] block w-4 h-4 rounded-full;
    }

    .tracker-dot_last {
        @apply left-auto md:left-0 -right-4 md:right-auto;
    }
}
